/* eslint-disable no-console,no-undef,camelcase */
import React, { Component, Suspense, lazy } from 'react';
import PropTypes from 'prop-types';
import { getStatus, getTimeToShow, getLastSyncDetails, convertDateInTimezone, commaSeperator, rankingDashboard, convertMilesToKm, convertMonthsWeeksText } from "../../../utils/methods";
import { connect } from "react-redux";
import Image from '../../Image';
import { joinOrLeaveChallenge, getInviteAmigosList } from "../../../redux/actions/challengeActions";
import { MyTaskContainerV2 } from '../MyTaskTab/styles';
import { EventsButton, ButtonContainer } from '../styles';
import { AboutContainer } from '../../EventDetails/styles';
import { PositionCard, LastSync, PositionCardV2, InformationCard, ParticipantCard } from '../OverViewScreen/styles';
import { Border } from '../../EventDetails/styles';
import moment from 'moment';
import isEmpty from 'lodash/isEmpty';
import { ImageUrl } from '../../../utils/constants';
import isNull from 'lodash/isNull';
import Waiting from '../../Waiting';
const InviteAmigoPopUp = lazy(() => import("../InviteAmigoPopUp/inviteChallengePopup"));
import isUndefined from 'lodash/isUndefined';
import { withTranslation } from 'react-i18next';
import {CompletePointCard} from '../styles';
import parse from 'react-html-parser';
// import LazyImage from '../../common/LazyImage/LazyImage';

class ClassicRightSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      time: [],
      isChallengeLocked: getStatus(props.challenge),
      isUpdatedImage: false,
      printChallengeCSV: false,
      updatedChallengeCSV: {},
      showInviteAmigoPopup: false,
      showParticipants: false,
      isButtonDisabled: false
    }
  }
  componentDidMount() {
    const { challenge } = this.props;
    // getUserOverviewDetails(challenge.id, challenge.event_type, this.props.userId);
    this.checkBase64(this.props.profileImage);
    this.interval = setInterval(() => {
      this.setState({
        time: getTimeToShow(challenge, 'challenge')
      })
    }, 1000);
  }

  joinChallenge = (challengeId, isJoined) => {
    const { joinOrLeaveChallenge } = this.props;
    this.setState({ isButtonDisabled: true });
    window.setTimeout(() => {
      this.setState({ isButtonDisabled: false });
    }, 2000);
    const data = {
      challenge_id: challengeId,
      is_joined: isJoined
    };
    joinOrLeaveChallenge(data);
  };
  checkBase64(profileImage) {
    if (profileImage) {
      let arr = profileImage.split('/');
      if (arr[0] !== 'profile-Image') {
        this.setState({
          isUpdatedImage: true
        })
      } else {
        this.setState({
          isUpdatedImage: false
        })
      }
    }
  }
  componentDidUpdate(prevProps) {
    if (prevProps.challenge !== this.props.challenge) {
      clearInterval(this.interval);
      this.interval = setInterval(() => {
        this.setState({
          time: getTimeToShow(this.props.challenge, 'challenge')
        })
      }, 1000);
    }
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.profileImage != nextProps.profileImage) {
      this.checkBase64(nextProps.profileImage)
    }
  }
  componentWillUnmount() {
    clearInterval(this.interval);
  }
  showProfileImage = () => {
    const { profileImage } = this.props;
    const { isUpdatedImage } = this.state;
    if (isUpdatedImage) {
      return (<img src={profileImage} alt="avatar" />)
    }
    else {
      return (<Image image={profileImage} alt="avatar" />)
    }

  };

  showHideInviteAmigoPopup = () => {
    this.setState((prevState) => ({
      showInviteAmigoPopup: !prevState.showInviteAmigoPopup
    }), () => this.props.getInviteAmigosList(this.props.userId, this.props.challenge.id))
  };

  hideInviteAmigoPopup = () => {
    this.setState({
      showInviteAmigoPopup: false
    })
  };

  render() {
    const { challenge, syncTime, userId, t, challengeAttendees} = this.props;
    const { time, isButtonDisabled } = this.state;
    if ((!(this.props.userChallengeDetails) || !userId)) {
      return <Waiting />
    }
    let challengeOver = true;
    let challengeCompetitors = {};
    let propsChallengeCompetitors = [];
    if (challenge.challenge_type === 'new_group') {
      const { user_details: userDetails } = this.props.userChallengeDetails;
      propsChallengeCompetitors = userDetails;

    } else {
      propsChallengeCompetitors = this.props.userChallengeDetails['user_details'];
    }

    time.map((step) => {
      const values = step.split(' ');
      if (values && parseInt(values[0],10) > 0) {
        challengeOver = false;
      }
    });
    const valid = convertDateInTimezone(challenge.end_date)._d >= moment()._d;
    if (challenge.challenge_status !== 'over' || valid) {
      challengeCompetitors = propsChallengeCompetitors;
    }
    else if (this.props.userChallengeDetails) {
      challengeCompetitors = propsChallengeCompetitors;
    }
    const syncUpdate = getLastSyncDetails(syncTime);
    const joinOrLeave = (challenge.id !== 53) ? !challengeOver && (challenge.challenge_status === 'joined' ? 0 : 1) : (challenge.challenge_status === 'joined' ? 0 : 1);
    const ChallengeType = challenge.event_type.split(' ')[1];
    let today = moment()._d;
    let startDay = convertDateInTimezone(challenge.start_date)._d;
    const DistanceUnit = localStorage.getItem('DISTANCE');
    let daysDifference = challenge && (new Date(challenge.end_date) -new Date(challenge.start_date))/(1000 * 60 * 60 * 24);
    return (
      <MyTaskContainerV2>
        {challenge.challenge_status == 'over' && !isEmpty(challengeCompetitors) && challengeCompetitors ?
          <CompletePointCard>
            <div className='width'>{"You recorded a whopping"}&nbsp;<div className='value'>{challenge.event_type === 'Total Steps' ?`${commaSeperator(challengeCompetitors['steps'])} ${this.props.t("Steps")}`:challenge.event_type === 'Total Calories'?`${commaSeperator(challengeCompetitors['calories'])} calories` :`${commaSeperator(challengeCompetitors['distance'])} Miles`}</div></div>
            <div className='width'>{"in just"}&nbsp; <div className='value'>{`${Math.floor(daysDifference)} Days earning`}&nbsp;{challenge.challenge_point} Points!</div></div>
          </CompletePointCard>:null}
        {challenge.challenge_status === 'joined' && !isEmpty(challengeCompetitors) && challengeCompetitors &&
          <PositionCardV2 color={challenge.event_type === 'Total Steps'?"#76AB78":challenge.event_type === 'Total Calories'?"#FFBF73":"#85C0EA"} width={"1"}>
            <div>
              <img src={`${ImageUrl}/${challengeCompetitors['profile_image']}`} className="profile" />
            </div>
            <div>
              <div>
                <div className="attendies">{t("Overall Position")}</div>
                {isNull(challengeCompetitors['user_rank']) ? <div>0</div> :
                  (today < startDay) ? <div>-</div> :
                    <div>{challengeCompetitors['user_rank']}{rankingDashboard(challengeCompetitors['user_rank'] - 1)} {t("Rank")}</div>}
              </div>
              <Border margin="auto 15px" background="#005C874D"/>
              <div>
                {challengeAttendees && challengeAttendees.length > 1 ? (isUndefined(challengeCompetitors['differed_by']) || isNull(challengeCompetitors['differed_by']) ? <Waiting /> : <div>{<span>{ChallengeType === 'Distance' ?DistanceUnit === "KILOMETER"?commaSeperator(convertMilesToKm(challengeCompetitors['distance_difference'])): commaSeperator(challengeCompetitors['distance_difference']) : ChallengeType == 'Steps' ? commaSeperator(challengeCompetitors['steps_difference']) : commaSeperator(challengeCompetitors['calories_difference'])}&nbsp;{ChallengeType === 'Distance' ?DistanceUnit === "KILOMETER"?t("kilometer"): t("miles") : ChallengeType == 'Steps' ? this.props.t('Steps') : this.props.t('Calories')}</span>}&nbsp;<span className="eventType">{challengeCompetitors['user_rank'] === 1 ? t('ahead from') : t("behind from")}&nbsp;{challengeCompetitors['differed_by']['fullname']}</span></div>) : <div style={{color:challenge.event_type === 'Total Steps'?"#76AB78":challenge.event_type === 'Total Calories'?"#FFBF73":"#85C0EA"}}>{ChallengeType === 'Distance' ?DistanceUnit === "KILOMETER"?t("- Kilometer"): t("- Miles") : ChallengeType == 'Steps' ? `- ${this.props.t("Steps")}` : `- ${this.props.t("Calories")}`} <div>&nbsp;{this.props.t("Behind")}</div></div>}
                <div />
              </div>
            </div>
          </PositionCardV2>}
        {(challenge.challenge_status === 'joined'||challenge.challenge_status === 'over') && !isNull(challenge.daily_goal) && challenge.daily_goal > 0 &&
          <InformationCard>
            <div className="image">
              <img src="/public/images/CompanyDashBoardV2/dailyGoal.png" alt="back-arrow" />
            </div>
            <div className="card-wrapper">
              <div className="first-card">
                <div className="field">
                  {t("My Daily Goal")}
                </div>
                <div className="value">
                  {challenge.event_type === 'Total Distance'?DistanceUnit === "KILOMETER"?commaSeperator(convertMilesToKm(challenge.daily_goal)): commaSeperator(challenge.daily_goal): commaSeperator(challenge.daily_goal)}
                </div>
              </div>
              <Border margin="auto 15px auto 0px" size="1" background="#005C874D"/>
              <div className="last-card">
                <div className="field">
                  {t("My Total Target Goal")}
                </div>
                <div className="value">
                  {challenge.event_type === 'Total Distance'?DistanceUnit === "KILOMETER"?commaSeperator(convertMilesToKm(challenge.target_goal)) :commaSeperator(challenge.target_goal): commaSeperator(challenge.target_goal)}
                </div>
              </div>
            </div>
          </InformationCard>
        }
        {(challenge.challenge_status === 'joined'||challenge.challenge_status === 'over') && !isEmpty(challengeCompetitors) && (challenge.event_type === 'Total Steps' ?
          <PositionCard color={'#76AB78'}>
            <div>
              <div className='img'>
                <img src={ImageUrl+"/ChallengeDetailsScreen/Steps.svg"} alt="back-arrow" />
              </div>
            </div>
            <div>
              <div>
                <div className="attendies">{t("My Total Steps")}
                </div>
                {challengeCompetitors['steps'] !== null ? (today < startDay) ? <div>-</div> : <div>{commaSeperator(challengeCompetitors['steps'])}
                </div> : (today < startDay) ? <div>-</div> : <div>0</div>}
              </div>
              <Border margin="auto 15px" background="#005C874D"/>
              <div>
                <div>{t("My Daily Average")}</div>
                {(today < startDay)?<div>-</div>:
                  <div>{commaSeperator(challengeCompetitors['daily_average'])}</div>}
              </div>
            </div>
          </PositionCard> :
          challenge.event_type === 'Total Calories' ?
            <PositionCard color={'#FFBF73'}>
              <div>
                <div className='img'>
                  <img src={ImageUrl+"/ChallengeDetailsScreen/Calories.svg"} alt="back-arrow" />
                </div>
              </div>
              <div>
                <div>
                  <div className="attendies">{t("My Total Calories")}
                  </div>
                  {challengeCompetitors['calories'] !== null ? (today < startDay) ? <div>-</div> : <div>{commaSeperator(challengeCompetitors['calories'])}
                  </div> : (today < startDay) ? <div>-</div> : <div>0</div>}
                </div>
                <Border margin="auto 15px" background="#005C874D"/>
                <div>
                  <div>{t("My Daily Average")}</div>
                  {(today < startDay)?<div>-</div>:
                    <div>{commaSeperator(challengeCompetitors['daily_average'])}</div>}
                </div>
              </div>
            </PositionCard> :
            <PositionCard color={'#85C0EA'}>
              <div>
                <div className='img'>
                  <img src={ImageUrl + "/ChallengeDetailsScreen/Distance.svg"}/>
                </div>
              </div>
              <div>
                <div>
                  <div className="attendies">{t("My Total Distance")}
                  </div>
                  {challengeCompetitors['distance'] !== null ? (today < startDay) ? <div>-</div> : <div>{DistanceUnit === "KILOMETER"?commaSeperator(convertMilesToKm(challengeCompetitors['distance'])):commaSeperator(challengeCompetitors['distance'])}
                  </div> : (today < startDay) ? <div>-</div> : <div>0</div>}
                </div>
                <Border margin="auto 15px" background="#005C874D"/>
                <div>
                  <div>{t("My Daily Average")}</div>
                  {(today < startDay)?<div>-</div>:
                    <div>{DistanceUnit === "KILOMETER"?commaSeperator(convertMilesToKm(challengeCompetitors['daily_average'])): commaSeperator(challengeCompetitors['daily_average'])}</div>}
                </div>
              </div>
            </PositionCard>)}
        {(challenge.challenge_status === 'joined'||challenge.challenge_status === 'over')&&<ParticipantCard >
          <div className='image'>
            <img src={ImageUrl + "/images/NewDashboardV2/EventsAttendies.png"}/>
          </div>
          <div className='text'>{this.props.t("Participants")}</div>
          <div className='count'>{challengeAttendees && challengeAttendees.length}</div>
        </ParticipantCard>}
        {(challenge.challenge_status === 'joined') && syncUpdate.length>0 && <LastSync>
          <div>{t("Last Sync")}</div>
          {!(today < startDay)?<div>{syncUpdate.length >= 2 ? ( localStorage.getItem("lang")!='fr' ? `${syncUpdate[0]} ${syncUpdate[1]} ${this.props.t("ago")}` : `${this.props.t("ago")} ${convertMonthsWeeksText(syncUpdate[0], this.props.t)}, ${convertMonthsWeeksText(syncUpdate[1], this.props.t)}`) : ( localStorage.getItem("lang")!='fr' ? `${syncUpdate[0]} ${this.props.t("ago")}` : `${this.props.t("ago")} ${convertMonthsWeeksText(syncUpdate[0], this.props.t)}`)}</div>:<div>-</div>}
        </LastSync>}
        {challenge.challenge_type == 'group' && challenge.challenge_status !== 'over' ?
          <ButtonContainer >
            {challenge.is_joined === 1 ?null:
              <EventsButton backgroundColor={"#005C87"} color={"white"} onClick={isButtonDisabled?null:() => this.joinChallenge(challenge.id, joinOrLeave)} padding={'15px 8px 15px 8px'} font={"18px"} margin={"25px"} boxShadow="0px 8px 24px 0px #005C8780">
                {t("Join Challenge")}
              </EventsButton>}
            {challenge.challenge_status !== 'joined' && challenge.challenge_status !== 'over' && !isNull(challenge.daily_goal) && challenge.daily_goal > 0 &&
              <InformationCard>
                <div className="image">
                  <img src="/public/images/CompanyDashBoardV2/dailyGoal.png" alt="back-arrow" />
                </div>
                <div className="card-wrapper">
                  <div className="first-card">
                    <div className="field">
                      {t("My Daily Goal")}
                    </div>
                    <div className="value">
                      {challenge.event_type === 'Total Distance'?DistanceUnit === "KILOMETER"?commaSeperator(convertMilesToKm(challenge.daily_goal)) :commaSeperator(challenge.daily_goal) :commaSeperator(challenge.daily_goal)}&nbsp;<div className="type">{challenge.event_type === 'Total Steps' ? t("Steps") : challenge.event_type === 'Total Calories' ? t("Calories") : DistanceUnit === "KILOMETER"?"Km": t("Miles")}</div>
                    </div>
                  </div>
                  <Border margin="auto 15px auto 0px" size="1" background="#005C874D"/>
                  <div className="last-card">
                    <div className="field">
                      {t("My Total Target Goal")}
                    </div>
                    <div className="value">
                      {challenge.event_type === 'Total Distance'?DistanceUnit === "KILOMETER"?commaSeperator(convertMilesToKm(challenge.target_goal)) :commaSeperator(challenge.target_goal) :commaSeperator(challenge.target_goal)}&nbsp;<div className="type">{challenge.event_type === 'Total Steps' ? t("Steps") : challenge.event_type === 'Total Calories' ? t("Calories") : DistanceUnit === "KILOMETER"?"Km": t("Miles")}</div>
                    </div>
                  </div>
                </div>
              </InformationCard>
            }
            <AboutContainer marginTop={'0px 0px 15px 0px'} color={"#005C87"} colorValue="#005C87">
              <div>{t("About The Challenge")}</div>
              <div >{parse(challenge.body)}</div>
            </AboutContainer>{/*  */}
            {challenge.is_joined === 1 ?
              <EventsButton backgroundColor={"#005C870D"} color={"#005C87"} padding={'15px 8px 15px 8px'} font={"18px"} cursor={"1"} margin={"25px"}>
                {t("You Joined This Challenge")}
              </EventsButton>:null}
          </ButtonContainer> : null}
        {challenge && challenge.challenge_status === 'over' &&
            <EventsButton backgroundColor={'#F4AAA94D'} color={"#005C87"} padding={'15px 8px 15px 8px'} font={"18px"} cursor={"1"}>
              {t("Challenge Completed")}
            </EventsButton>}
        <Suspense fallback={<Waiting/>}><InviteAmigoPopUp userId={userId} history={this.props.history} showModal={this.state.showInviteAmigoPopup} onHide={this.showHideInviteAmigoPopup} challengeId={challenge.id} plusAmigoButton={1} hideInviteAmigoPopup={this.hideInviteAmigoPopup}/></Suspense>
      </MyTaskContainerV2>
    )
  }
}
ClassicRightSection.propTypes = {
  getUserOverviewDetails: PropTypes.func.isRequired,
  userChallengeDetails: PropTypes.object,
  challenge: PropTypes.object.isRequired,
  userId: PropTypes.number.isRequired,
  profileImage: PropTypes.string.isRequired,
  joinOrLeaveChallenge: PropTypes.func,
  syncTime: PropTypes.string,
  getInviteAmigosList: PropTypes.func,
  history: PropTypes.object,
  role: PropTypes.string,
  t: PropTypes.func,
  challengeAttendees: PropTypes.array
};
const mapStateToProps = (state) => ({
  userChallengeDetails: state.challenges.userChallengeDetails,
  challengeAttendees: state.challenges.challengeAttendees,
  syncTime: state.challenges.syncTime,
});
const mapDispatchToProps = (dispatch) => ({
  joinOrLeaveChallenge: (data) => dispatch(joinOrLeaveChallenge(data)),
  getInviteAmigosList: (userID, challengeId) => dispatch(getInviteAmigosList(userID, challengeId)),
});
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ClassicRightSection));
